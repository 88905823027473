<template>
  <div class="container">
    <div class="maintenance">
      <img src="/web/img/icons/maintenance-icon.svg" alt="Maintenance" />
      <p class="main-text">
        {{ $t('maintenance.text1') }}
      </p>
      <h2>{{ $t('maintenance.text2') }}</h2>
      <p>{{ $t('maintenance.text3') }}</p>
    </div>
  </div>
</template>

<script>
import { pageTitle } from '@/base/const/routing.const'

export default {
  name: 'Maintenance',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().maintenance
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/scss/mixins";
  .maintenance {
    padding: 40px 0;
    max-width: 480px;

    @include md {
      padding: 80px 0;
      margin-left: 120px;
    }

    img {
      margin-bottom: 30px;

      @include md {
        margin-bottom: 40px;
      }
    }

    .main-text {
      margin-bottom: 30px;
    }

    h2 {
      color: var(--color-text);
      margin-bottom: 30px;
    }
  }
</style>
